$(function() {

   /*  ============================================================================
       Scrolling Navbar JS
       ========================================================================= */
     $(document).scroll(function () {
       var $nav = $(".fixed-top");
       $nav.toggleClass('scrolled', $(this).scrollTop() > $nav.height());
     });
   
   /*  ============================================================================
       GSAP JS
       ========================================================================= */
   // ------------------------------------------------------------- setup variables
   
}); // main page load fxn
   